
import { motion, useSpring, useTransform, useInView, AnimatePresence, cubicBezier, useAnimate, stagger } from 'framer-motion';
import { useRef, useState, useEffect, useContext } from 'react';
import { useInViewScroll, MotionImage } from 'ui';
import { useRouter } from 'next/router';
import Link from 'next/link';

const calculateTimeLeft = (targetDate) => {
  const difference = new Date(targetDate) - new Date();

  let timeLeft = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

const useCountdown = (targetDate) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeLeft(calculateTimeLeft(targetDate));
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDate]);

  return [timeLeft.days, timeLeft.hours, timeLeft.minutes, timeLeft.seconds]
    .map(val => String(val).padStart(2,'0'));
};

const Digit = ({value}) => {
  
  return <span className="relative border h-full border-[#fff6] text-white w-5 lg:w-6 rounded-md overflow-clip">
    <AnimatePresence>
      <motion.span 
        key={value}
        initial={{opacity: 0, y: -10}}
        animate={{opacity: 1, y: 0}}
        exit={{opacity: 0, y: 10}}
        transition={{ease: 'circInOut'}}
        className="inline-block absolute w-full h-full px-[2px] flex items-center justify-center lg:px-1">
        {value}
      </motion.span>
    </AnimatePresence>
    
  </span>
}

const date = new Date('11-01-2024')
export const Banner = () => {
    const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
    const scrollY = useTransform(amt, [0, 1], [0, 50]);


    const [slide, setSlide] = useState(0);
    // const intervalId = useRef(-1)

    // useEffect(() => {
    //   intervalId.current = setInterval(() => {
    //     setSlide(slide => (slide+1)%2);
    //   }, 10000);
  
    //   return () => clearInterval(intervalId.current);
    // }, [slide]);

    // const goto = (num) => {
    //   clearInterval(intervalId.current)
    //   setSlide(num)
    // }
  
    const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%'});
    
    const backgrounds = [
      'linear-gradient(to bottom, #1131f5, #000062)',
      'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'
    ]

    const pathname = useRouter().asPath;

    const isTv = pathname.indexOf('products/xumo-tv') >= 0

    const ctaUrl = isTv 
      ? "https://xumo.com"
      : "https://www.xumo.com"

    const product = isTv 
      ? "Xumo TV" 
      : "Xumo Stream Box"
  
    return <motion.section 
      initial={{opacity: 0, y: 40}}
      animate={{opacity: inView ? 1 : 0, y: inView ? 0 : 40}}
      transition={{ease: [0,1,1,1]}}
      ref={ref} className="relative z-40 bg-white w-full" data-testid={'text-section'}>
      <div className="wrapper my-2">
        
        <Link 
          href={ctaUrl} 
          aria-labelledby="banner-cta-label" 
          className={`relative block rounded-3xl cursor-pointer group outline outline-2 outline-transparent outline-offset-2 hover:outline-xumoTeal transition-colors overflow-clip`} 
          style={{background: backgrounds[slide] }}>
          {/* container */}
          <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0
            }}
            transition={{ease:'cubicInOut'}}
            className="flex flex-col md:flex-row">
            <div className="p-6 lg:p-8 xl:p-12 pr-6 xl:min-w-auto flex shrink-0 text-white items-center">
              <div className="relative h-full w-auto min-h-[40px] max-lg:max-h-[40px] max-xl:max-h-[50px] lg:min-h-[50px] aspect-[366/100]">
                <MotionImage
                  src="/static/images/refresh/Max_logo.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0}}
                  className="object-fit" />
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="max-md:content-wrapper grow pt-0 md:pr-6 md:py-4 flex flex-col min-w-[0px] min-h-full items-start justify-center text-white type-body">
              <span className="block text-lg lg:text-xl xl:text-2xl mb-1">Black Friday Deal</span>
              <span id="banner-cta-label" className="text-sm lg:text-base">70% off! <span className="line-through decoration-[#00f0ff] decoration-2">$9.99/mo</span> $2.99 for 6 months.<br/><span className="block mt-2 underline decoration-2 decoration-[#00f0ff] underline-offset-4 group-hover:decoration-[#00f0ffcc] transition-colors">Subscribe&nbsp;to&nbsp;Max&nbsp;today&nbsp;with&nbsp;Xumo</span></span>
              
              <div className="mt-2 text-xs text-left justify-start text-[#fff7] weight-300">
                Valid on the With Ads plan only. Offer ends 12/2/24. See max.com for terms.
              </div>
            </motion.div>
            <div className="hidden md:flex lg:min-w-[38%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip rounded-3xl pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full will-change-transform">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/hbo-quilt-blackfriday.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 1 ? 0 : -10,
              opacity: slide === 1 ? 1 : 0,
              zIndex: slide === 1 ? 5 : 0
            }}
            className="absolute flex top-0 left-0 w-full h-full">
            <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
              <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={days[0]} />
                    <Digit value={days[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={hours[0]} />
                    <Digit value={hours[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={minutes[0]} />
                    <Digit value={minutes[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={seconds[0]} />
                    <Digit value={seconds[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
                </div>
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
              <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
            </motion.div>
            <div className="flex md:min-w-[40%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip rounded-3xl pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/black-friday-devices.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  }

  export const Banner2 = () => {
    const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
    const scrollY = useTransform(amt, [0, 1], [0, 50]);


    const [slide, setSlide] = useState(0);
    // const intervalId = useRef(-1)

    // useEffect(() => {
    //   intervalId.current = setInterval(() => {
    //     setSlide(slide => (slide+1)%2);
    //   }, 10000);
  
    //   return () => clearInterval(intervalId.current);
    // }, [slide]);

    // const goto = (num) => {
    //   clearInterval(intervalId.current)
    //   setSlide(num)
    // }
  
    const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%'});
    
    const backgrounds = [
      'linear-gradient(to bottom, #1131f5, #000062)',
      'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'
    ]

    const pathname = useRouter().asPath;

    const isTv = pathname.indexOf('products/xumo-tv') >= 0

    const ctaUrl = isTv 
      ? "https://xumo.com"
      : "https://www.xumo.com"

    const product = isTv 
      ? "Xumo TV" 
      : "Xumo Stream Box"
  
    return <motion.section 
      initial={{opacity: 0, y: 40}}
      animate={{opacity: inView ? 1 : 0, y: inView ? 0 : 40}}
      transition={{ease: [0,1,1,1]}}
      ref={ref} className="relative z-40 bg-white w-full mt-16" data-testid={'text-section'}>
      <div className="wrapper my-2">
        
        <Link 
          href={ctaUrl} 
          aria-labelledby="banner-cta-label" 
          className={`relative block rounded-3xl cursor-pointer group outline outline-2 outline-transparent outline-offset-2 hover:outline-xumoTeal transition-colors overflow-clip`} 
          style={{background: backgrounds[slide] }}>
          {/* container */}
          <div className="absolute w-full h-[calc(100%+50px)]">
            <div className="w-full h-full absolute -top-[50px] right-0 overflow-clip rounded-3xl pointer-events-none">
              <MotionImage
                style={{y:scrollY}}
                initial={{opacity: 0}}
                animate={{opacity: inView ? 1 : 0}}
                transition={{delay: 0.4}}
                src="/static/images/refresh/max-quilt-bg.jpg"
                priority
                fill
                sizes="100vw"
                className="object-cover" />
            </div>
          </div>
          <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0
            }}
            transition={{ease:'cubicInOut'}}
            style={{background: 'linear-gradient(to bottom, #0006, #000f'}}
            className="flex flex-col md:flex-row relative z-5">
            <div className="p-6 lg:p-8 xl:p-12 pr-6 xl:min-w-auto flex shrink-0 text-white items-center">
              <div className="relative h-full w-auto min-h-[40px] max-lg:max-h-[40px] max-xl:max-h-[50px] lg:min-h-[50px] aspect-[366/100]">
                <MotionImage
                  src="/static/images/refresh/Max_logo.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0}}
                  className="object-fit" />
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="max-md:content-wrapper grow pt-0 md:pr-6 md:py-4 flex flex-col min-w-[0px] min-h-full items-start justify-center text-white type-body">
              <span className="block text-lg lg:text-xl xl:text-2xl mb-1">Black Friday Deal</span>
              <span id="banner-cta-label" className="text-sm lg:text-base">70% off! <span className="line-through decoration-[#00f0ff] decoration-2">$9.99/mo</span> $2.99 for 6 months.<br/><span className="block mt-2 underline decoration-2 decoration-[#00f0ff] underline-offset-4 group-hover:decoration-[#00f0ffcc] transition-colors">Subscribe&nbsp;to&nbsp;Max&nbsp;today&nbsp;with&nbsp;Xumo</span></span>
              
              <div className="mt-2 text-xs text-left justify-start text-[#fff7] weight-300">
                Valid on the With Ads plan only. Offer ends 12/2/24. See max.com for terms.
              </div>
            </motion.div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 1 ? 0 : -10,
              opacity: slide === 1 ? 1 : 0,
              zIndex: slide === 1 ? 5 : 0
            }}
            className="absolute flex top-0 left-0 w-full h-full">
            <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
              <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={days[0]} />
                    <Digit value={days[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={hours[0]} />
                    <Digit value={hours[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={minutes[0]} />
                    <Digit value={minutes[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={seconds[0]} />
                    <Digit value={seconds[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
                </div>
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
              <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
            </motion.div>
            <div className="flex md:min-w-[40%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip rounded-3xl pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/black-friday-devices.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  }

  export const Banner3 = () => {
    const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
    const scrollY = useTransform(amt, [0, 1], [0, 50]);


    const [slide, setSlide] = useState(0);
    const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%'});
    
    const backgrounds = [
      'linear-gradient(to bottom, #1131f5, #000062)',
      'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'
    ]

    const pathname = useRouter().asPath;

    const isTv = pathname.indexOf('products/xumo-tv') >= 0

    const ctaUrl = isTv 
      ? "https://xumo.com"
      : "https://www.xumo.com"

    const product = isTv 
      ? "Xumo TV" 
      : "Xumo Stream Box"
  
    return <motion.section 
      initial={{opacity: 0, y: 40}}
      animate={{opacity: inView ? 1 : 0, y: inView ? 0 : 40}}
      transition={{ease: [0,1,1,1]}}
      ref={ref} className="relative z-40 bg-white w-full mt-16" data-testid={'text-section'}>
      <div className="wrapper my-2">
        
        <Link 
          href={ctaUrl} 
          aria-labelledby="banner-cta-label" 
          className={`relative block cursor-pointer group outline outline-2 outline-transparent outline-offset-2 hover:outline-[#00f0ff] transition-colors overflow-clip`} 
          style={{background: backgrounds[slide] }}>
          {/* container */}
          <div className="relative z-5 bg-[#00f0ff] text-[#03f] weight-600 tracking-wider text-center uppercase p-2">
            Offer ends Monday
          </div>
          <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0
            }}
            transition={{ease:'cubicInOut'}}
            className="flex flex-col md:flex-row relative z-5">
            <div className="absolute w-full h-full">
              <div className="w-full h-full absolute right-0 overflow-clip pointer-events-none">
                <MotionImage
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0.4}}
                  src="/static/images/refresh/max-bg.jpg"
                  priority
                  fill
                  sizes="100vw"
                  className="object-cover object-right" />
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="content-wrapper-x relative z-5 py-4 grow flex flex-col min-w-[0px] min-h-full items-start justify-center text-white type-body">
              <div className="flex grow w-full">
                <div className="flex flex-col">
                  <span className="text-md">&nbsp;</span> 
                  <span className="text-4xl uppercase weight-300 leading-none"><span className="weight-600">Black Friday</span> Deal</span>
                </div>
                <div className="flex flex-col grow ml-8">
                  <span className="line-through text-md decoration-[#00f0ff] decoration-2 text-[#7178d4]">$9.99/mo</span> 
                  <span id="banner-cta-label" className="text-4xl flex flex-row weight-600 text-[#00f0ff] leading-none"><span className="weight-300 scale-90">$</span>2.99 <span className="flex items-center pl-2 text-sm uppercase leading-tight weight-400 text-white">for<br/>6 months</span></span>
                </div>
              </div>
              
              <div className="text-xs text-left justify-start mt-2 text-[#fff9] weight-300">
                Valid on the With Ads plan only. Offer ends 12/2/24. See max.com for terms.
              </div>
            </motion.div>
            <div className="content-wrapper-x relative z-5 py-4 flex flex-col grow text-white items-center justify-center ">
              <div className="relative h-[32px] aspect-[366/100]">
                <MotionImage
                  src="/static/images/refresh/Max_logo.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0}}
                  className="object-fit" />
              </div>
              <div className="group-hover:bg-transparent group-hover:text-[#00f0ff] border border-1 border-[#00f0ff] bg-[#00f0ff] text-[#03f] rounded-full py-1 px-3 uppercase text-sm mt-2 weight-500 transition-colors">Sign up now</div>
            </div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 1 ? 0 : -10,
              opacity: slide === 1 ? 1 : 0,
              zIndex: slide === 1 ? 5 : 0
            }}
            className="absolute flex top-0 left-0 w-full h-full">
            <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
              <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={days[0]} />
                    <Digit value={days[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={hours[0]} />
                    <Digit value={hours[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={minutes[0]} />
                    <Digit value={minutes[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={seconds[0]} />
                    <Digit value={seconds[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
                </div>
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
              <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
            </motion.div>
            <div className="flex md:min-w-[40%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/black-friday-devices.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  }


  export const Banner4 = () => {
    const { ref, progress: amt } = useInViewScroll([0, 1], ['start 100vh', 'end 0vh']);
    const scrollY = useTransform(amt, [0, 1], [0, 50]);


    const [slide, setSlide] = useState(0);
    const inView = useInView(ref, { once: true, margin: '-30% 0% -30% 0%'});
    
    const backgrounds = [
      'linear-gradient(to bottom, #1131f5, #000062)',
      'linear-gradient(to top, rgb(20, 13, 19), rgb(91, 46, 79))'
    ]

    const pathname = useRouter().asPath;

    const isTv = pathname.indexOf('products/xumo-tv') >= 0

    const ctaUrl = isTv 
      ? "https://xumo.com"
      : "https://www.xumo.com"

    const product = isTv 
      ? "Xumo TV" 
      : "Xumo Stream Box"
  
    return <motion.section 
      initial={{opacity: 0, y: 40}}
      animate={{opacity: inView ? 1 : 0, y: inView ? 0 : 40}}
      transition={{ease: [0,1,1,1]}}
      ref={ref} className="relative z-40 bg-white w-full mt-16" data-testid={'text-section'}>
      <div className="wrapper my-2">
        
        <Link 
          href={ctaUrl} 
          aria-labelledby="banner-cta-label" 
          className={`relative block cursor-pointer group outline outline-2 outline-transparent outline-offset-2 hover:outline-[#00f0ff] transition-colors overflow-clip rounded-3xl`}>
          {/* container */}
          <div className="relative z-5 bg-[#00f0ff] text-[#03f] weight-600 tracking-wider text-center uppercase p-2">
            Offer ends Monday
          </div>
          <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 0 ? 0 : -10,
              opacity: slide === 0 ? 1 : 0,
              zIndex: slide === 0 ? 5 : 0
            }}
            transition={{ease:'cubicInOut'}}
            style={{background: 'linear-gradient(to right, #1131f5 27%, #000062 100%)'}}
            className="flex  relative z-5">
            <div className="absolute w-full h-full">
              <div className="w-full h-full absolute right-0 overflow-clip pointer-events-none">
                <MotionImage
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0.4}}
                  src="/static/images/refresh/max-bg.jpg"
                  priority
                  fill
                  sizes="100vw"
                  className="object-cover object-right" />
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="content-wrapper-x relative z-5 py-4 grow flex flex-col min-w-[0px] min-h-full items-start justify-center text-white type-body">
              <div className="flex grow flex-col lg:flex-row w-full">
                <div className="flex flex-col">
                  <span className="text-md hidden lg:inline">&nbsp;</span> 
                  <span className="text-3xl xl:text-4xl 2xl:text-5xl uppercase weight-300 leading-none"><span className="weight-600">Black Friday</span> Deal</span>
                </div>
                <div className="flex flex-col grow lg:ml-8">
                  <span className="line-through text-md decoration-[#00f0ff] decoration-2 text-[#7178d4]">$9.99/mo</span> 
                  <span id="banner-cta-label" className="text-3xl xl:text-4xl 2xl:text-5xl flex flex-row weight-600 text-[#00f0ff] leading-none"><span className="weight-300 scale-80">$</span>2.99<span className="uppercase weight-300 scale-80">/mo</span> <span className="flex items-center pl-2 text-xs xl:text-sm 2xl:text-lg uppercase leading-tight weight-400 text-white">for<br/>6 months</span></span>
                </div>
              </div>
              
              <div className="text-xs text-left justify-start mt-2 text-[#fff9] weight-300">
                Valid on the With Ads plan only.<br className="lg:hidden"/> Offer ends 12/2/24. See max.com for terms.
              </div>
            </motion.div>
            <div className="content-wrapper-x relative z-5 py-4 flex flex-col grow text-white items-start justify-center ">
              <div className="relative h-[32px] aspect-[366/100]">
                <MotionImage
                  src="/static/images/refresh/Max_logo.png"
                  priority
                  fill
                  sizes="100vw"
                  initial={{opacity: 0}}
                  animate={{opacity: inView ? 1 : 0}}
                  transition={{delay: 0}}
                  className="object-fit" />
              </div>
              <div className="group-hover:bg-transparent group-hover:text-[#00f0ff] border border-1 border-[#00f0ff] bg-[#00f0ff] text-[#03f] rounded-full py-1 px-3 uppercase text-sm mt-2 weight-500 transition-colors">Sign up now</div>
            </div>
          </motion.div>
          {/*container*/}

          {/* container */}
          {/* <motion.div 
            initial={{
              x: 0,
              opacity: 1
            }}
            animate={{
              x: slide === 1 ? 0 : -10,
              opacity: slide === 1 ? 1 : 0,
              zIndex: slide === 1 ? 5 : 0
            }}
            className="absolute flex top-0 left-0 w-full h-full">
            <div className="p-6 lg:p-8 xl:p-16 xl:pr-8 flex text-white">
              <div className="relative h-full min-h-[60px] text-lg xl:text-xl weight-600 gap-2 flex">
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={days[0]} />
                    <Digit value={days[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">D</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={hours[0]} />
                    <Digit value={hours[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">H</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={minutes[0]} />
                    <Digit value={minutes[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">M</div>
                </div>
                <div className="text-center flex flex-col items-center">
                  <div className="flex grow gap-[2px] justify-center items-center">
                    <Digit value={seconds[0]} />
                    <Digit value={seconds[1]} />
                  </div>
                  <div className="flex shrink text-[#fff6] text-xs weight-300">S</div>
                </div>
              </div>
            </div>
            <motion.div 
              initial={{opacity: 0}}
              animate={{opacity: inView ? 1 : 0}}
              transition={{delay: 0.2}}
              className="pr-4 py-4 flex grow min-h-full items-center text-white type-body">
              <span>Don’t miss these Black Friday offers! <span className="underline decoration-2 decoration-[#fff6] underline-offset-4 group-hover:decoration-[#fffc] transition-colors">Shop&nbsp;deals</span></span>
            </motion.div>
            <div className="flex md:min-w-[40%] min-h-full relative">
              <div className="aspect-[2] w-full absolute bottom-0 right-0 overflow-clip pointer-events-none">
                <div className="group-hover:scale-[1.02] transition-all relative w-full h-full">
                  <MotionImage
                    style={{y:scrollY}}
                    initial={{opacity: 0}}
                    animate={{opacity: inView ? 1 : 0}}
                    transition={{delay: 0.4}}
                    src="/static/images/refresh/black-friday-devices.png"
                    priority
                    fill
                    sizes="100vw"
                    className="object-fit" />
                </div>
              </div>
            </div>
          </motion.div> */}
          {/*container*/}
        </Link>
      </div>
    </motion.section>
  }